export const breakpoints = {
  values: {
    // MUI defaults
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    // Custom:
    maxContentWidth: 1760,
  },
}
