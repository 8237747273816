import { Roles } from '../constants/roles'
import { User } from '../interfaces/User'

import { containsSome } from './functional'

// = inbound callcenter where lawyers are creating cases for us
export const isTRBManager = (user?: User) => !!user && containsSome([Roles.TRBManager])(user.roles)
export const isTRB = (user?: User) => !!user && (containsSome([Roles.TRB])(user.roles) || isTRBManager(user))
// = Chancellery employee
export const isLawyer = (user?: User) => !!user && containsSome([Roles.Lawyer])(user.roles)
// Channel (=DEVK employee)
export const isChannel = (user?: User) => !!user && containsSome([Roles.Channel])(user.roles)
// = Us
export const isAdmin = (user?: User) => !!user && containsSome([Roles.Administrator])(user.roles)
// = vocateur (i.e those who connect customers and chancellerys)
export const isCallcenter = (user?: User) => !!user && containsSome([Roles.Callcenter])(user.roles)
// = Klugo employee. Not DEVK
export const isEmployee = (user?: User) => !!user && containsSome([Roles.Employee])(user.roles)
