import { USER_AUTH_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils/requests'

import query from '../graphql/resetPassword.graphql'

export const setPasswordMutation = async (resetToken: string, newPassword: string): Promise<boolean> => {
  const variables = {
    resetToken,
    newPassword,
  }
  return request<boolean>(USER_AUTH_ENDPOINT, query, variables)
}
