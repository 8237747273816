import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { AsyncFunction, request } from 'common/utils'

import getChancelleriesQuery from '../graphql/getChancelleries.graphql'
import { GetChancelleriesQuery, GetChancelleriesQueryVariables } from '../interfaces/schemaDefinition'

export const fetchChancelleries: AsyncFunction<
  GetChancelleriesQueryVariables,
  GetChancelleriesQuery['chancelleries']['list']
> = async (variables: GetChancelleriesQueryVariables) => {
  const response = await request<GetChancelleriesQuery>(CHANCELLERIES_ENDPOINT, getChancelleriesQuery, variables)
  if (variables.pageSize && response.chancelleries.total > variables.pageSize) {
    return (
      await request<GetChancelleriesQuery>(CHANCELLERIES_ENDPOINT, getChancelleriesQuery, {
        ...variables,
        pageSize: response.chancelleries.total,
      })
    ).chancelleries.list
  }
  return response.chancelleries.list
}
