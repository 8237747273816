import { FieldError } from 'react-hook-form'

import { PriceFormat, getPrice } from '../utils'

type UseBruttoPrice = <T extends { netPrice: string; grossPrice: string }>(
  initialValues: T | null,
  values: { netPrice: string; grossPrice: string },
  error?: FieldError
) => { price: string; touchedNetPrice: boolean }

export const useBruttoPrice: UseBruttoPrice = (initialValues, values, error) => {
  const touchedNetPrice: boolean =
    !!(initialValues && initialValues.netPrice !== values.netPrice) || !!(!initialValues && values.netPrice)
  const price = getPrice(PriceFormat.NoUnit, values.netPrice, values.grossPrice, touchedNetPrice, error && error.message)
  return { price, touchedNetPrice }
}
