import React, { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Grid } from 'common/components-mui'
import { Autocomplete } from 'common/components-mui/react-hook-form'

import { MarketingConfigurationProps } from '../interfaces'

import { Accordion } from './Accordion'

const AWINSaleTypeOptions = Array.from(new Array(10), (_, index) => `sales${index + 1}`)

export const MarketingConfiguration: FC<MarketingConfigurationProps> = ({ loading, existingProduct }) => {
  const { control } = useFormContext()
  const [expanded, setExpanded] = useState<boolean>(!existingProduct)

  return (
    <Accordion title="Marketing" expanded={expanded} onChange={(_, isExpanded) => setExpanded(isExpanded)}>
      <Grid container>
        <Grid xs={12} md={6}>
          <Autocomplete
            control={control}
            name="marketing.awinSaleType"
            isOptionEqualToValue={(option, value) => option === value}
            label="AWIN-Saletype"
            disabled={loading}
            ChipProps={{ color: 'primary' }}
            options={AWINSaleTypeOptions}
            fullWidth
          />
        </Grid>
      </Grid>
    </Accordion>
  )
}
