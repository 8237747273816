/* eslint-disable fp/no-rest-parameters */
import { TextField, TextFieldProps } from '@mui/material'
import MuiAutocomplete from '@mui/material/Autocomplete'
import React from 'react'
import { Controller } from 'react-hook-form'

import { ControlledAutocomplete } from 'common/interfaces'

export const Autocomplete: ControlledAutocomplete<TextFieldProps> = props => {
  const { name, label, control, onBeforeChange, disabled, options, sortSelected, ...rest } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { invalid, error } }) => (
        <MuiAutocomplete
          disabled={disabled}
          options={options}
          {...field}
          {...rest}
          value={field.value || null}
          onChange={(_event, newValue, _options) => {
            if (onBeforeChange) {
              onBeforeChange(field.value, newValue)
            }
            if (Array.isArray(newValue) && sortSelected) {
              const copy = newValue.slice(0)
              // eslint-disable-next-line fp/no-mutating-methods
              copy.sort(sortSelected)
              field.onChange(copy)
            } else {
              field.onChange(newValue)
            }
          }}
          renderInput={params => <TextField {...params} error={invalid} helperText={error?.message} label={label} name={name} />}
        />
      )}
    />
  )
}
