import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { GridColumns, GridRowParams } from '@mui/x-data-grid'
import React, { FC, useState } from 'react'

import { createGridColumnsFrom } from 'common/components-mui'

import { FeatureConfigurationProps } from '../interfaces'
import { ProductFormValues, ProductFeature } from '../interfaces/form'
import { Feature } from '../interfaces/schemaDefinition'

import { AccordionTable } from './AccordionTable'
import { FeatureDialog } from './FeatureDialog'
import { GridColumnFeatureType } from './GridColumnFeatureType'

export const FeatureConfiguration: FC<FeatureConfigurationProps<ProductFormValues['features']>> = ({
  value: features,
  onChange,
  existingProduct,
}) => {
  const [editFeatureId, setEditFeatureId] = useState('')
  const [expanded, setExpanded] = useState<boolean>(!existingProduct)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const columns: GridColumns = createGridColumnsFrom([
    {
      field: 'sort',
      headerName: 'Sortierung',
      filterable: false,
      sortable: false,
      flex: 0.1,
      renderCell: params => {
        const sort = (direction: 1 | -1, id: string): void => {
          const oldIndex = features.findIndex(feature => feature.id === id)
          const newIndex = direction + oldIndex
          const remains = features.filter(feature => feature.id !== id)

          const result = [...remains.slice(0, newIndex), features[oldIndex], ...remains.slice(newIndex)]
          onChange(result)
        }

        return (
          <Box key={params.row.id}>
            <IconButton
              onClick={e => {
                e.stopPropagation()
                sort(-1, params.row.id)
              }}
              color="primary"
              disabled={params.row.sort === 1}
            >
              <ArrowUpward />
            </IconButton>
            <IconButton
              onClick={e => {
                e.stopPropagation()
                sort(1, params.row.id)
              }}
              color="primary"
              disabled={params.row.sort === features.length}
            >
              <ArrowDownward />
            </IconButton>
          </Box>
        )
      },
    },
    /* eslint-disable @typescript-eslint/consistent-type-assertions */
    {
      field: 'name',
      sortable: false,
      headerName: 'Leistung',
      valueFormatter: params => params.value as Feature['name'],
      flex: 0.3,
    },
    {
      field: 'type',
      sortable: false,
      headerName: 'Leistungsart',
      renderCell: params => <GridColumnFeatureType type={params.value} />,
      flex: 0.2,
    },
    {
      field: 'info',
      sortable: false,
      headerName: 'Zusätzliche Informationen',
      valueFormatter: params => params.value as Feature['info'],
      flex: 0.4,
    },
  ])

  const handleRowClick = (params: GridRowParams): void => {
    setEditFeatureId(params.row.id)
    setOpenModal(true)
  }

  const handleSubmitFeature = (feature: ProductFeature, close: boolean): void => {
    const index = features.findIndex(s => s.id === feature.id)
    if (index >= 0) {
      onChange([...features.slice(0, index), feature, ...features.slice(index + 1)])
    } else {
      onChange([...features, feature])
    }
    !close && setEditFeatureId(feature.id)

    setOpenModal(!close)
  }

  const handleDeleteFeature = (): void => {
    const index = features.findIndex(s => s.id === editFeatureId)
    if (index >= 0) {
      onChange([...features.slice(0, index), ...features.slice(index + 1)])
    }
    setOpenModal(false)
  }

  return (
    <AccordionTable
      title="Leistungen"
      expanded={expanded}
      onChange={(_, isExpanded) => setExpanded(isExpanded)}
      columns={columns}
      rows={features.map((feature, index) => ({ ...feature, sort: index + 1 }))}
      dialogs={
        <FeatureDialog
          open={openModal}
          feature={editFeatureId}
          features={features}
          onClose={() => setOpenModal(false)}
          onSubmit={handleSubmitFeature}
          onDelete={handleDeleteFeature}
        />
      }
      onRowClick={params => handleRowClick(params)}
      addButtonTitle="Leistung hinzufügen"
      onClickAddButton={() => {
        setEditFeatureId('')
        setOpenModal(true)
      }}
    />
  )
}
