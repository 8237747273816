export const COUNTRIES = [
  'Albanien',
  'Algerien',
  'Arabische Länder',
  'Belgien',
  'Bosnien-Herzegowina',
  'Bulgarien',
  'Dänemark',
  'Deutschland',
  'Estland',
  'Finnland',
  'Frankreich',
  'Griechenland',
  'Großbritannien & Nordirland',
  'Italien',
  'Israel',
  'Irland',
  'Island',
  'Kanada',
  'Kosovo',
  'Kroatien',
  'Lettland',
  'Liechtenstein',
  'Litauen',
  'Luxemburg',
  'Malta',
  'Marokko',
  'Moldawien',
  'Montenegro',
  'Niederlande',
  'Nordmazedonien',
  'Norwegen',
  'Österreich',
  'Polen',
  'Portugal',
  'Rumänien',
  'Russische Föderation',
  'Schweden',
  'Schweiz',
  'Serbien',
  'Slowakei',
  'Spanien',
  'Slowenien',
  'Tschechische Republik',
  'Tunesien',
  'Türkei',
  'Ukraine',
  'Ungarn',
  'USA',
  'Weißrussland',
  'Zypern',
]
