import * as z from 'zod'

import { hasRoleChannel, hasRoleLawyer } from '../utils/checkRole'

const baseSchema = z.object({
  deleted: z.boolean().optional(),
  active: z.boolean().optional(),
  groups: z.array(z.object({ id: z.string(), name: z.string() })).optional(),
  rights: z.array(z.string()).optional(),
  organisationType: z.string().nullable(),
  parentId: z.string().optional(),
  sendRegisterEmail: z.boolean().optional(),
  name: z
    .string({
      required_error: 'Benutzername erforderlich.',
    })
    .trim()
    .min(2, 'Benutzername zu kurz')
    .max(50, 'Benutzername zu lang.'),
  email: z
    .string({
      required_error: 'E-Mail Adresse ungültig',
    })
    .trim()
    .nonempty('E-Mail Adresse ungültig')
    .email('E-Mail Adresse ungültig'),
  roles: z.array(z.object({ id: z.string(), name: z.string() })),
  channel: z.string().optional(),
  organisation: z.string().optional().nullable(),
})

const passwordSchemaUpdate = baseSchema
  .extend({
    id: z.string(),
    password: z.string(),
    passwordRepeat: z.string(),
  })
  .refine(({ password, passwordRepeat }) => password === passwordRepeat, {
    message: 'Die Passwörter müssen übereinstimmen.',
    path: ['password'],
  })
  .refine(
    ({ password }) => {
      if (!password) return true
      const test = z.string().min(8)
      return test.safeParse(password).success
    },
    {
      message: 'Das Password muss mindestens 8 Zeichen lang sein.',
      path: ['password'],
    }
  )

const passwordSchemaCreate = baseSchema
  .extend({
    id: z.undefined(),
    password: z
      .string({ required_error: 'Für neue Benutzer muss ein Passwort vergeben werden.' })
      .trim()
      .nonempty('Für neue Benutzer muss ein Passwort vergeben werden.')
      .min(8, 'Das Password muss mindestens 8 Zeichen lang sein.'),
    passwordRepeat: z.string(),
  })
  .refine(({ password, passwordRepeat }) => password === passwordRepeat, {
    message: 'Die Passwörter müssen übereinstimmen.',
    path: ['password'],
  })

/**
 * If validation fails, because `id` is missing,
 * it will check for the schema defined in `.or()`.
 * This is a way toimplement conditional schemas
 * similar to `.when()` or `is -> then` in `yup`.
 * ---
 * See: https://github.com/colinhacks/zod/issues/1394#issuecomment-1346013265
 */
export const userSchemaCreate = passwordSchemaCreate
  .refine(
    ({ roles, channel }) => {
      const isChannel = hasRoleChannel(roles)
      return isChannel ? !!channel : true
    },
    { message: 'Ein Rechtsberater Nutzer muss einem Kanal zugewiesen werden.', path: ['channel'] }
  )
  .refine(
    ({ roles, organisation }) => {
      const isLawyer = hasRoleLawyer(roles)
      return isLawyer ? !!organisation : true
    },
    { message: 'Kanzlei erforderlich.', path: ['roles'] }
  )

export const userSchemaUpdate = passwordSchemaUpdate
  .refine(
    ({ roles, channel }) => {
      const isChannel = hasRoleChannel(roles)
      return isChannel ? !!channel : true
    },
    { message: 'Ein Rechtsberater Nutzer muss einem Kanal zugewiesen werden.', path: ['channel'] }
  )
  .refine(
    ({ roles, organisation }) => {
      const isLawyer = hasRoleLawyer(roles)
      return isLawyer ? !!organisation : true
    },
    { message: 'Kanzlei erforderlich.', path: ['organisation'] }
  )

export type FormUser = z.infer<typeof userSchemaUpdate | typeof userSchemaCreate>

export const initialValues: FormUser = {
  name: '',
  email: '',
  deleted: false,
  active: true,
  roles: [],
  groups: [],
  channel: '',
  rights: [],
  organisation: '',
  organisationType: '',
  parentId: '',
  password: '',
  passwordRepeat: '',
  sendRegisterEmail: undefined,
}
