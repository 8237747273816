import { USER_AUTH_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils'

import query from '../graphql/getUserRoles.graphql'
import { GetUserRolesQuery } from '../interfaces/schemaDefinition'

export const fetchUserRoles: () => Promise<GetUserRolesQuery['roles']> = async () => {
  const response = await request<GetUserRolesQuery>(USER_AUTH_ENDPOINT, query)
  return response.roles
}
