import { formatCurrency, formatCurrencyNoUnit } from '@grapes-agency/universal'

/* eslint-disable fp/no-mutation */
const VAT = 0.19

// The following calculations are identical to the backend calculations. Any changes here need to be changed in backend, too!!!
export const convertToNum = (price: string): number => parseFloat(price.replace(',', '.'))

export const roundDecimal = (num: number, dec: number): number => Math.round((num + Number.EPSILON) * 10 ** dec) / 10 ** dec

const convertPercentage = (discount: string): number => roundDecimal((100 - convertToNum(discount)) / 100, 4)

export const calculatePrice = (netPrice: string, discount?: string, discountedBrutto?: boolean): string => {
  if (!netPrice) {
    return ''
  }

  // eslint-disable-next-line fp/no-let
  let price: number
  if (discount && discountedBrutto) {
    price = roundDecimal(convertToNum(netPrice) * convertPercentage(discount) * (1 + VAT), 2)
  } else if (discount) {
    price = roundDecimal(convertToNum(netPrice) * convertPercentage(discount), 2)
  } else {
    price = roundDecimal(convertToNum(netPrice) * (1 + VAT), 2)
  }
  return price.toString().replace('.', ',')
}

export const convertPercentageToDecimal = (discount: string): number =>
  Math.round((convertToNum(discount) + Number.EPSILON) * 100) / 10 ** 4

const getPriceHelperFunction = (
  netPrice: string,
  price: string,
  touchedNetPrice: boolean,
  gross?: boolean,
  discount?: string,
  error?: string
): string => (error ? '0' : touchedNetPrice ? calculatePrice(netPrice, discount, !!gross) : price)

export enum PriceFormat {
  WithUnit = 'WithUnit',
  NoUnit = 'NoUnit',
  NoCurrency = 'NoCurrency',
}

export const getPrice = (
  priceFormat: PriceFormat,
  netPrice: string,
  price: string,
  touchedNetPrice: boolean,
  error?: string,
  gross?: boolean,
  discount?: string
): string => {
  if (priceFormat === PriceFormat.WithUnit) {
    return formatCurrency({ unit: 'EUR' })(
      convertToNum(getPriceHelperFunction(netPrice, price, touchedNetPrice, gross, discount, error))
    )
  } else if (priceFormat === PriceFormat.NoUnit) {
    return formatCurrencyNoUnit(convertToNum(getPriceHelperFunction(netPrice, price, touchedNetPrice, gross, discount, error)))
  } else {
    return getPriceHelperFunction(netPrice, price, touchedNetPrice, gross, discount, error)
  }
}

export const isKebabCase = (value: string): boolean => !!value && /^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/.test(value)
