import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import { GridCellParams, GridColumns } from '@mui/x-data-grid'
import React, { FC, FunctionComponent, memo } from 'react'
import { useHistory } from 'react-router'

import {
  createGridColumnsFrom,
  AddButton,
  FilterOperator,
  MuiDataGrid,
  useGridData,
  addZipAreaOperators,
  CenterBox,
  Badge,
} from 'common/components-mui'
import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { ConfigWeight } from 'common/graphql/schemaDefinition'

import { weightMap } from '../constants'
import matchingConfigsQuery from '../graphql/getMatchingConfigurations.graphql'
import { MatchingConfigurationsTableProps } from '../interfaces'
import { FieldsOfLaw, GetMatchingConfigurationsQuery, Partners, Products } from '../interfaces/schemaDefinition'
import { listFormatter } from '../utils'

import { InputLegend } from './InputLegend'

const getStatus: FC<GridCellParams> = (params: GridCellParams) => (
  <CenterBox>
    <Badge color={params.value === true ? 'success' : 'default'}>{params.value === true ? 'Aktiv' : 'Inaktiv'}</Badge>
  </CenterBox>
)
const dataObjectName = 'matchingConfigs'

const columns: GridColumns = createGridColumnsFrom([
  /* eslint-disable @typescript-eslint/consistent-type-assertions */
  {
    field: 'zipAreas',
    headerName: 'PLZ',
    sortable: false,
    filterOperators: addZipAreaOperators(),
    valueFormatter: params => (params.value as Array<string>).join(', '),
    flex: 0.167,
  },
  {
    field: 'fieldsOfLaw',
    sortable: false,
    filterable: false,
    headerName: 'Rechtsgebiete',
    valueFormatter: params => (params.value as FieldsOfLaw).included.map(f => f.name).join(', '),
    flex: 0.167,
  },
  {
    field: 'partners',
    headerName: 'Partner',
    sortable: false,
    filterable: false,
    valueFormatter: params => listFormatter((params.value as Partners).included),
    flex: 0.167,
  },
  {
    field: 'products',
    headerName: 'Produkte',
    sortable: false,
    filterable: false,
    valueFormatter: params => listFormatter((params.value as Products).included),
    flex: 0.167,
  },
  {
    field: 'weight',
    headerName: 'Gewichtung',
    filterable: false,
    valueFormatter: params => weightMap[params.value as ConfigWeight]?.toLowerCase() ?? 'normal',
    flex: 0.167,
  },
  {
    field: 'active',
    headerName: 'Status',
    filterable: false,
    renderCell: getStatus,
    flex: 0.167,
  },
  /* eslint-enable @typescript-eslint/consistent-type-assertions */
])

const MatchingConfigurationsTableInternal: FC<MatchingConfigurationsTableProps> = ({ chancelleryLocationId }) => {
  const history = useHistory()
  const { data, error, actions, tableState } = useGridData<typeof dataObjectName, GetMatchingConfigurationsQuery>(
    CHANCELLERIES_ENDPOINT,
    matchingConfigsQuery,
    dataObjectName,
    {
      filters: [
        // Only get configurations for the current location
        {
          name: 'chancelleryLocationId',
          value: chancelleryLocationId,
          operator: FilterOperator.Equals,
        },
        // Only get configurations which are not marked as deleted.
        // We don't show them ever.
        {
          name: 'deleted',
          value: false,
          operator: FilterOperator.Equals,
        },
        // Only get configurations which are not marked as fallback.
        // They are shown separately.
        {
          name: 'fallback',
          value: false,
          operator: FilterOperator.Equals,
        },
      ],
    }
  )

  const listHasElements = (data?.list?.length ?? 0) > 0

  return (
    <Box mb={3} component="fieldset">
      <InputLegend>Konfigurationen</InputLegend>
      <AddButton
        aria-label="Konfiguration hinzufügen"
        // eslint-disable-next-line fp/no-mutating-methods
        onClick={() => history.push(`/chancelleries/configurations/${chancelleryLocationId}/new`)}
      />
      {error ? (
        <Alert severity="error">Ein Fehler ist beim Laden der Konfigurationen aufgetreten.</Alert>
      ) : (
        <MuiDataGrid
          actions={actions}
          tableState={tableState}
          columns={listHasElements ? columns : []}
          rows={data?.list ?? []}
          loading={!data}
          components={{}}
          hideFooter={!listHasElements}
          // eslint-disable-next-line fp/no-mutating-methods
          onRowClick={r => (r.row.id ? history.push(`/chancelleries/configurations/edit/${r.row.id}`) : undefined)}
        />
      )}
    </Box>
  )
}

export const MatchingConfigurationsTable: FunctionComponent<MatchingConfigurationsTableProps> = memo(
  MatchingConfigurationsTableInternal
)
