/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { FC, MouseEvent, useState } from 'react'

import { Tooltip } from 'common/components-mui'

import { GridColumnPartnerProps } from '../interfaces'
import { Partner } from '../interfaces/schemaDefinition'

export const GridColumnPartner: FC<GridColumnPartnerProps> = ({ params }) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false)

  const handleTooltip = ({ currentTarget }: MouseEvent<Element>): void => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true)
    }
  }

  return (
    <Tooltip
      title={(params.value as Array<Pick<Partner, 'id' | 'name'>>).map(p => p.name).join(', ')}
      onMouseEnter={handleTooltip}
      onMouseLeave={() => setTooltipEnabled(false)}
      disableHoverListener={!tooltipEnabled}
    >
      <span
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {(params.value as Array<Pick<Partner, 'id' | 'name'>>).map(p => p.name).join(', ')}
      </span>
    </Tooltip>
  )
}
