import { Column, SortDirection } from 'common/interfaces'

import { AdviceForeignForm } from '../interfaces'

export { CASE_DATA_FIELDS } from './caseDataFields'
export { EMPTY_ADVICE_REQUEST } from './emptyAdviceRequest'
export { COUNTRIES } from './countries'
export { STATUS_TEXT, STATUS_COLORS } from './AdviceStatus'
export { adviceForeignFormSchema } from './adviceForeignFormSchema'

export const adviceForeignFormInitialValues: AdviceForeignForm = {
  damageNumber: '',
  country: null,
  fieldOfLaw: null,
  description: '',
}

export const DEFAULT_PAGE_SIZE = 50
export const DEFAULT_PAGE = 1
export const DEFAULT_SORT_DIRECTION = SortDirection.Desc
export const DEFAULT_SORT_BY: Column = 'createdAt'

export const DEFAULT_LIST_STATE = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  sort: {
    sortBy: DEFAULT_SORT_BY,
    sortDirection: DEFAULT_SORT_DIRECTION,
  },
  filters: [],
}

export const PAGE_URL = '/advice-requests'
export const NEW_URL = '/advice-requests/form'
