import { MatchChancelleriesQuery, RowChancellery } from '../interfaces'
import { formatAddress } from '../utils/formatAddress'

export const mapResponseToRowChancelleries = (
  response: MatchChancelleriesQuery['matchChancelleries']
): Array<RowChancellery> | [] =>
  response.chancelleries
    .filter(e => !e.fallback)
    .map(chancellery => ({
      id: chancellery.id,
      name: chancellery.name,
      phone: chancellery.phone,
      address: formatAddress(chancellery.address),
      total: chancellery.total || 0,
    }))
