import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { FC } from 'react'

import { DialogProps } from 'packages-mui/products/interfaces'

export const Dialog: FC<DialogProps> = ({ open, title, onClose, actions, children }) => (
  <MuiDialog
    open={open}
    onClose={onClose}
    sx={{
      '& .MuiDialog-container': { width: '100%' },
      '& .MuiPaper-root': { width: 'inherit' },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <DialogTitle
      sx={{
        backgroundColor: '#f7f7f9',
        color: 'primary',
        fontWeight: 400,
        fontSize: '1.5rem',
      }}
    >
      {title}
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions sx={{ p: '0.5rem 1.5rem' }}>{actions}</DialogActions>
  </MuiDialog>
)
