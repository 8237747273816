import { Link as MuiLink } from '@mui/material'
import React, { ComponentProps, FC } from 'react'
import { NavLink } from 'react-router-dom'

export const Link: FC<ComponentProps<typeof MuiLink> & { to: string }> = props => {
  const { children } = props
  return (
    <MuiLink component={NavLink} variant="loginLink" {...props}>
      {children}
    </MuiLink>
  )
}
