import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton } from '@mui/material'
import dayjs from 'dayjs'
import React, { FC } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { AddButton } from 'common/components-mui'
import { Autocomplete, Checkbox, DatePicker } from 'common/components-mui/react-hook-form'
import { FIELDS_OF_LAW_ENTRIES } from 'common/constants/fieldsOfLaw'
import { FieldOfLaw } from 'common/graphql/schemaDefinition'

import { locationInitialValues } from '../interfaces/formSchemas'
import { getNewHolidayEntry } from '../utils'

import { InputLegend } from './InputLegend'

export const HolidaySettings: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { control, watch } = useFormContext<typeof locationInitialValues>()
  const holidays = watch('holidays')

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'holidays',
  })
  return (
    <>
      <InputLegend>Urlaubszeiten</InputLegend>
      <Box mb={2}>
        <AddButton
          aria-label="Urlaubszeitraum hinzufügen"
          disabled={disabled}
          onClick={() => append({ ...getNewHolidayEntry(), fieldsOfLaw: [] })}
        />
      </Box>
      {fields.map((field, index) => (
        <Box
          key={field.id}
          display="grid"
          alignItems="center"
          gap={1}
          gridTemplateColumns="4fr 4fr 1fr"
          sx={{
            ':not(:last-child)': {
              borderBottom: '1px solid rgba(0,0,0,.23)',
              paddingBottom: '1.5rem',
              marginBottom: '1.5rem',
            },
          }}
        >
          <DatePicker name={`holidays.${index}.start`} control={control} label="Von" fullWidth disabled={disabled} />
          <DatePicker
            name={`holidays.${index}.end`}
            control={control}
            label="Bis"
            fullWidth
            minDate={dayjs(holidays[index].start || '')}
            disabled={disabled}
          />
          <Box>
            <IconButton onClick={() => remove(index)} disabled={disabled} size="large">
              <DeleteIcon />
            </IconButton>
          </Box>
          <Checkbox
            name={`holidays.${index}.global`}
            control={control}
            label="Gültig für alle Standorte"
            sx={{ gridColumn: '1/3' }}
            disabled={disabled}
          />
          <Autocomplete
            name={`holidays.${index}.fieldsOfLaw`}
            label={
              (holidays[index]?.fieldsOfLaw?.length || 0) > 0
                ? 'Gilt für ausgewählte Rechtsgebiete'
                : 'Gilt für alle Rechtsgebiete (oder auswählen)'
            }
            control={control}
            sx={{ gridColumn: '1/3' }}
            disabled={disabled || (holidays[index].global ?? false)}
            multiple
            options={FIELDS_OF_LAW_ENTRIES}
            getOptionLabel={(option: FieldOfLaw) => (typeof option === 'object' && option ? option.name : option)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
            limitTags={5}
            ChipProps={{ color: 'primary' }}
          />
        </Box>
      ))}
    </>
  )
}
