import { object, string } from 'zod'

import { confirmPasswordErrorMessages, emailErrorMessages, passwordErrorMessages } from './schemaErrorMessages'

export const loginSchema = object({
  email: string(emailErrorMessages).email(emailErrorMessages.invalid_type_error),
  password: string(passwordErrorMessages).trim().nonempty(passwordErrorMessages.invalid_type_error),
})

export const passwordSetSchema = object({
  password: string(passwordErrorMessages).trim().nonempty(passwordErrorMessages.required_error),
  confirmPassword: string(confirmPasswordErrorMessages).trim().nonempty(confirmPasswordErrorMessages.required_error),
})

export const passwordResetSchema = object({
  email: string(emailErrorMessages).email(emailErrorMessages.invalid_type_error),
})
