import React, { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Checkbox } from 'common/components-mui/react-hook-form'

import { MatchingConfigurationProps } from '../interfaces'

import { Accordion } from './Accordion'

export const MatchingConfiguration: FC<MatchingConfigurationProps> = ({ loading, existingProduct }) => {
  const { control } = useFormContext()
  const [expanded, setExpanded] = useState<boolean>(!existingProduct)
  return (
    <Accordion title="Matching" expanded={expanded} onChange={(_, isExpanded) => setExpanded(isExpanded)}>
      <Checkbox control={control} name="matching.ignoreOrderVolume" label="Auftragsvolumen ignorieren" disabled={loading} />
    </Accordion>
  )
}
