import * as z from 'zod'

export const partnerSchema = z.object({
  name: z
    .string({
      invalid_type_error: 'Bitte geben Sie einen Namen für den Partner an.',
      required_error: 'Bitte geben Sie einen Namen für den Partner an.',
    })
    .trim()
    .nonempty('Bitte geben Sie einen Namen für den Partner an.'),
  phone: z
    .string({
      invalid_type_error: 'Bitte geben Sie eine Telefonnummer für den Partner an.',
      required_error: 'Bitte geben Sie eine Telefonnummer für den Partner an.',
    })
    .min(4, 'Die Telefonnummer muss mindestens vier Ziffern enthalten.')
    .regex(/^(\+|00|0)/, 'Die Telefonummer muss mit einer internationalen Vorwahl (z.B. 0049 oder +49) oder einer "0" beginnen.')
    .regex(/^(\+|00|0)[1-9][0-9 ]*/, 'Die Telefonnummer darf nur aus Nummern und Leerzeichen bestehen.'),

  active: z.boolean(),
  locked: z.boolean(),
  id: z.string().trim().nonempty(),
  insurer: z.string(),
  agbsPreAccepted: z.boolean(),
  discount: z.boolean(),
  hasCustomerValidation: z.boolean(),

  platform: z.object({
    consumer: z.boolean(),
    business: z.boolean(),
  }),
  customerIdValidation: z.object({
    pattern: z.string(),
    description: z.string(),
  }),
})

export const partnerInitialValues: z.infer<typeof partnerSchema> = {
  name: '',
  phone: '',

  active: true,
  locked: false,
  id: '',
  insurer: '',
  agbsPreAccepted: false,
  hasCustomerValidation: false,
  discount: false,
  platform: {
    consumer: false,
    business: false,
  },
  customerIdValidation: {
    pattern: '',
    description: '',
  },
}
