import { difference } from 'lodash'
import useSWR from 'swr'

import { USER_AUTH_ENDPOINT } from 'common/constants'
import { enqueueSnackbar } from 'common/utils'

import { fetchUserRoles } from '../actions'
import { Role } from '../interfaces'

export const useRoleSelection = (
  rights: Array<string>
): {
  disabled: boolean
  getNewRights: (newValue: Array<Role>, oldValue: Array<Role>) => Array<string>
} => {
  const { data: rolesWithRights = [], error } = useSWR([USER_AUTH_ENDPOINT, 'roles'], fetchUserRoles)

  if (error) {
    enqueueSnackbar('Rollen konnten nicht abgerufen werden', { variant: 'error' })
  }

  const getRightsFromRoles = (roleIds: Array<string>): Array<string> => {
    const matchingRolesWithRights = rolesWithRights?.filter(role => roleIds.includes(role.id))
    const rightsOfRoles = matchingRolesWithRights?.flatMap(r => r.rights.map(right => right.id))
    return rightsOfRoles ?? []
  }

  const getNewRights = (oldValue: Array<Role>, newValue: Array<Role>): Array<string> => {
    const removedRoles = difference(oldValue, newValue)
    const addedRoles = difference(newValue, oldValue)

    if (addedRoles.length > 0) {
      const roleIds = addedRoles.map(el => el.id)
      const addedRightsOfRoles = getRightsFromRoles(roleIds)
      return [...rights, ...addedRightsOfRoles]
    }

    if (removedRoles.length > 0) {
      const roleIds = removedRoles.map(el => el.id)
      const removedRightsOfRoles = getRightsFromRoles(roleIds)
      return difference(rights, removedRightsOfRoles)
    }

    return rights
  }

  return {
    disabled: !rolesWithRights && !error,
    getNewRights,
  }
}
