import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material'
import React, { FC } from 'react'

import theme from 'theme/theme'

// eslint-disable-next-line fp/no-rest-parameters
export const Tooltip: FC<MuiTooltipProps> = ({ title, children, ...props }) => (
  <MuiTooltip
    title={<span style={{ fontSize: '12px' }}>{title}</span>}
    componentsProps={{
      tooltip: {
        sx: {
          // TODO: add backgroundColor in theme
          backgroundColor: '#f7f7f9',
          color: theme.palette.primary.main,
          boxShadow: theme.shadows[1],
        },
      },
    }}
    {...props}
  >
    {children}
  </MuiTooltip>
)
