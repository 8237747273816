import { darken } from '@mui/material'

import { CreateMuiComponentOverrides } from 'theme/types/MuiComponentOverrides'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    loginContained: true
  }
}

export const createMuiButtonTheme: CreateMuiComponentOverrides<'MuiButton'> = theme => ({
  variants: [
    {
      props: { variant: 'loginContained' },
      style: {
        textTransform: 'none',
        borderRadius: theme.spacing(1.25),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        ':hover': {
          backgroundColor: darken(theme.palette.primary.main, 0.3),
        },
      },
    },
    {
      props: { variant: 'loginContained', color: 'secondary' },
      style: {
        textTransform: 'none',
        borderRadius: theme.spacing(1.25),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        ':hover': {
          backgroundColor: darken(theme.palette.common.white, 0.1),
        },
      },
    },
  ],
})
