import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Typography } from '@mui/material'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { DeleteWithConfirmation, Grid, PageLayout, SaveButton } from 'common/components-mui'
import { TextField } from 'common/components-mui/react-hook-form'
import { useParams } from 'common/hooks'
import { enqueueSnackbar } from 'common/utils'

import { createFieldOfLaw, deleteFieldOfLaw, getFieldOfLaw, updateFieldOfLaw } from '../actions'
import { fieldOfLawSchema } from '../interfaces/formSchemas'
import { FieldOfLawInput } from '../interfaces/schemaDefinition'

export const FieldsOfLawDetailPage: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { control, reset, getValues, handleSubmit } = useForm<FieldOfLawInput>({
    resolver: zodResolver(fieldOfLawSchema),
    defaultValues: {
      id: '',
      name: '',
    },
  })

  const isNew = id === 'new'
  // eslint-disable-next-line fp/no-mutating-methods
  const redirect = (path: string): void => history.push(path)

  const onError = (): void => {
    enqueueSnackbar('Rechtsgebiet konnte nicht gespeichert werden.', { variant: 'error' })
  }

  const onSubmit = async (values: FieldOfLawInput): Promise<boolean> => {
    setIsLoading(true)
    const payload = isNew ? { id: values.name, name: values.name } : values
    try {
      const fieldOfLaw = isNew
        ? (await createFieldOfLaw(payload)).createFieldOfLaw
        : (await updateFieldOfLaw(payload)).updateFieldOfLaw

      if (isNew) {
        redirect(`/fields-of-law/${fieldOfLaw.id}`)
      }
      enqueueSnackbar(isNew ? 'Rechtsgebiet erfolgreich angelegt.' : 'Rechtsgebiet wurde aktualisiert.', { variant: 'success' })
      setIsLoading(false)
      return true
    } catch (error) {
      onError()
      setIsLoading(false)
      return false
    }
  }

  const onConfirmDelete = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const deletedFieldOfLaw = await deleteFieldOfLaw(getValues('id'))
      enqueueSnackbar(`Rechtsgebiet mit der ID: ${deletedFieldOfLaw?.deleteFieldOfLaw.id} wurde gelöscht.`, {
        variant: 'success',
      })
      redirect('/fields-of-law')
    } catch (error) {
      enqueueSnackbar('Rechtsgebiet konnte nicht gelöscht werden.', { variant: 'error' })
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isNew) return
    setIsLoading(true)
    getFieldOfLaw(id)
      .then(({ fieldOfLaw: fetchedFol }) => {
        reset(fetchedFol)
      })
      .catch(_err => {
        enqueueSnackbar(`Rechtsgebiet mit der ID: ${id} konnte nicht gefunden werden.`, { variant: 'error' })
      })
      .finally(() => setIsLoading(false))
  }, [isNew, id, reset])

  const heading = `Rechtsgebieteverwaltung: Rechtsgebiet ${id ? 'bearbeiten.' : 'anlegen.'}`
  return (
    <PageLayout heading={heading} spacing="table">
      <Grid
        id="rechtsgebiet"
        sx={{ padding: 2 }}
        container
        component="form"
        spacing={2}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Grid xs={6}>
          <TextField fullWidth name="id" label="ID" disabled aria-readonly control={control} />
        </Grid>
        <Grid xs={6}>
          <TextField fullWidth name="name" label="Name" disabled={isLoading} control={control} />
        </Grid>
      </Grid>
      <Box display="flex" alignItems="flex-start" justifyContent="flex-end" sx={{ paddingX: 2 }}>
        <SaveButton disabled={isLoading} submit form="rechtsgebiet">
          Speichern
        </SaveButton>
        <SaveButton
          disabled={isLoading}
          onClick={() => onSubmit(getValues()).then(shouldRedirect => shouldRedirect && redirect('/fields-of-law'))}
        >
          Speichern und schließen
        </SaveButton>
        <Button disabled={isLoading} variant="outlined" onClick={() => redirect('/fields-of-law')} sx={{ marginTop: 0 }}>
          Abbrechen
        </Button>
        {!isNew && (
          <DeleteWithConfirmation disabled={isLoading} onConfirm={onConfirmDelete}>
            <Typography>
              Diese Aktion ist nicht umkehrbar. Das Rechtsgebiet <strong>{getValues('name')}</strong> wird unwiderruflich gelöscht
            </Typography>
          </DeleteWithConfirmation>
        )}
      </Box>
    </PageLayout>
  )
}
