import { FilterOperator, ListState, SortDirection } from 'common/interfaces'

export const listState: ListState = {
  page: 1,
  pageSize: 100,
  sort: {
    sortBy: 'name',
    sortDirection: SortDirection.Asc,
  },
  filters: [
    {
      name: 'tags',
      value: '',
      operator: FilterOperator.Empty,
    },
  ],
}
