import useSWR from 'swr'

import { CHANCELLERIES_ENDPOINT, FIELDS_OF_LAW_ENDPOINT } from 'common/constants'
import { fetcher } from 'common/utils'
import { CoverageErrorType } from 'packages-mui/users/interfaces/schemaDefinition'

import fallbackCoverageQuery from '../graphql/getFallbackCoverage.graphql'
import fieldsOfLawQuery from '../graphql/getFieldsOfLaw.graphql'
import { ApiResponse } from '../interfaces'
import {
  CoverageMissingProblem,
  CoverageTemporaryMissingProblem,
  GetFallbackCoverageQuery,
  GetFieldsOfLawQuery,
} from '../interfaces/schemaDefinition'

// It's too complicated to extract the type of the coverage problems.
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFallbackCoverage = () => {
  const variables = {
    page: 0,
    pageSize: 5000,
    config: {
      fallbacks: [CoverageErrorType.MissingField, CoverageErrorType.TemporaryMissingField],
    },
  }
  const { data: coverageData, error: coverageError } = useSWR([CHANCELLERIES_ENDPOINT, fallbackCoverageQuery], (e, q) =>
    fetcher<ApiResponse<GetFallbackCoverageQuery>>(e, q, variables)
  )
  const { data: folData, error: folError } = useSWR([FIELDS_OF_LAW_ENDPOINT, fieldsOfLawQuery], (e, q) =>
    fetcher<ApiResponse<GetFieldsOfLawQuery>>(e, q, variables)
  )

  if (coverageError) {
    return { error: coverageError, data: undefined }
  }

  if (folError) {
    return { error: folError, data: undefined }
  }

  if (!coverageData || !folData) {
    return { error: undefined, data: undefined }
  }

  const missingProblems =
    coverageData.data.matchingConfigCoverage.fallbacks.problems?.list.filter(
      (p): p is CoverageMissingProblem => p.errorType === CoverageErrorType.MissingField
    ) ?? []
  const temporaryMissingProblems =
    coverageData.data.matchingConfigCoverage.fallbacks.problems?.list.filter(
      (p): p is CoverageTemporaryMissingProblem => p.errorType === CoverageErrorType.TemporaryMissingField
    ) ?? []

  const problems = {
    data: {
      missingProblems: missingProblems.map(problem => ({
        ...problem,
        // We don't need __typename
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        fieldOfLaw: folData.data.fieldsOfLaw.list.find(field => field.id === problem.fieldOfLawId),
      })),
      temporaryMissingProblems: temporaryMissingProblems.map(problem => ({
        ...problem,
        // We don't need __typename
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        fieldOfLaw: folData.data.fieldsOfLaw.list.find(field => field.id === problem.fieldOfLawId),
      })),
    },
    error: undefined,
  }
  return problems
}
