import { Role } from 'common/api/types'
import { ROLES, Roles, ROLE_IDS } from 'common/constants'
import { LoggedInUser } from 'common/user-context'

export const getMostImportantRole = (user?: LoggedInUser): Role | undefined => {
  if (!user) {
    return undefined
  }

  if (user.roles.indexOf(Roles.Administrator) >= 0) {
    return ROLES[ROLE_IDS.Administrator]
  }
  if (user.roles.indexOf(Roles.Employee) >= 0) {
    return ROLES[ROLE_IDS.Employee]
  }

  const statusRoleMap = {
    [Roles.Callcenter]: ROLES[ROLE_IDS.Callcenter],
    [Roles.Channel]: ROLES[ROLE_IDS.Channel],
    [Roles.TRB]: ROLES[ROLE_IDS.TRB],
    [Roles.TRBManager]: ROLES[ROLE_IDS.TRBManager],
  }

  const firstFoundRelevantRole = user.roles.find(role =>
    [Roles.Callcenter, Roles.Channel, Roles.TRB, Roles.TRBManager].includes(role)
  )

  return firstFoundRelevantRole ? statusRoleMap[firstFoundRelevantRole] : undefined
}
