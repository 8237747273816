import ExpandCircleIcon from '@mui/icons-material/ExpandCircleDown'
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary as MuiAccordionSummary, Typography } from '@mui/material'
import React, { FC } from 'react'

import { AccordionProps } from '../interfaces'

export const Accordion: FC<AccordionProps> = ({ title, dialogs, expanded, onChange, children }) => (
  <MuiAccordion expanded={expanded} onChange={onChange} sx={{ boxShadow: 'none', '&:before': { content: 'none' } }}>
    <MuiAccordionSummary
      expandIcon={<ExpandCircleIcon color="primary" />}
      sx={{
        padding: 0,
        gap: '1rem',
        flexDirection: 'row-reverse',
      }}
    >
      <Typography sx={{ fontSize: '1.5rem' }}>{title}</Typography>
    </MuiAccordionSummary>
    <AccordionDetails sx={{ padding: '0.5rem 0' }}>{children}</AccordionDetails>
    {dialogs}
  </MuiAccordion>
)
