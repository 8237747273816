import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AssignmentIcon from '@mui/icons-material/Assignment'
import BusinessIcon from '@mui/icons-material/Business'
import GavelIcon from '@mui/icons-material/Gavel'
import PeopleIcon from '@mui/icons-material/People'
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback'
import ShopTwoIcon from '@mui/icons-material/ShopTwo'

import { Roles, URLs } from 'common/constants'
import { Route } from 'common/interfaces'
import { CreateAdviceNavLink } from 'packages/chancellery-search/components/CreateAdviceNavLink'

export const adminRoutes: ReadonlyArray<Route> = [
  {
    url: URLs.users.home,
    roles: [Roles.Administrator],
    nav: {
      svg: PeopleIcon,
      label: 'Nutzer',
    },
    children: [
      {
        url: URLs.users.home,
        label: 'Nutzerverwaltung',
      },
      {
        url: URLs.users.new,
        label: 'Neuer Nutzer',
      },
    ],
  },
  {
    url: URLs.chancelleries.home,
    roles: [Roles.Administrator, Roles.Employee],
    nav: {
      label: 'Kanzleien',
      svg: AccountBalanceIcon,
    },
    children: [
      {
        url: URLs.chancelleries.home,
        label: 'Kanzleiverwaltung',
      },
      {
        url: '/chancelleries/configurations',
        label: 'Konfigurationsverwaltung',
      },
      {
        url: '/chancelleries/locations-volumes',
        label: 'Standortvolumen',
      },
      {
        url: URLs.chancelleries.new,
        label: 'Neue Kanzlei',
      },
    ],
  },
  {
    url: URLs.adviceRequests.home,
    roles: [Roles.Administrator, Roles.Employee, Roles.Callcenter, Roles.Channel, Roles.TRB, Roles.TRBManager],
    nav: {
      label: 'Anträge',
      svg: AssignmentIcon,
    },
    children: [
      {
        url: URLs.adviceRequests.home,
        label: 'Antragsverwaltung',
      },
      {
        url: URLs.adviceRequests.form,
        label: 'Antrag erfassen',
        customLink: CreateAdviceNavLink,
      },
    ],
  },
  {
    url: URLs.adviceRequests.callbacks,
    roles: [Roles.Administrator, Roles.Employee, Roles.Callcenter],
    nav: {
      label: 'Rückrufe',
      svg: PhoneCallbackIcon,
    },
  },
  {
    url: URLs.products.home,
    roles: [Roles.Administrator, Roles.Employee],
    nav: {
      label: 'Produkte',
      svg: ShopTwoIcon,
    },
  },
  {
    url: URLs.productsBusiness.home,
    roles: [Roles.Administrator, Roles.Employee],
    nav: {
      label: 'Produkte B2B',
      svg: ShopTwoIcon,
    },
  },
  {
    url: URLs.partners.home,
    roles: [Roles.Administrator, Roles.Employee],
    nav: {
      label: 'Partner',
      svg: BusinessIcon,
    },
  },
  {
    url: URLs.fieldsOfLaw.home,
    roles: [Roles.Administrator, Roles.Employee],
    nav: {
      label: 'Rechtsgebiete',
      svg: GavelIcon,
    },
  },
]
