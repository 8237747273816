import { EnqueueSnackbar, enqueueSnackbar as notistackEnqueueSnackbar, SnackbarMessage, SnackbarProviderProps } from 'notistack'

export const enqueueSnackbar: EnqueueSnackbar = (message: SnackbarMessage, options?: SnackbarProviderProps) =>
  notistackEnqueueSnackbar(message, {
    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
    autoHideDuration: 5000,
    disableWindowBlurListener: false,
    hideIconVariant: false,
    preventDuplicate: false,
    transitionDuration: { enter: 225, exit: 195 },
    variant: 'default',
    ...options,
  })
