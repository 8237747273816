import { PARTNERS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import createPartnerMutation from '../graphql/createPartner.graphql'
import deletePartnerMutation from '../graphql/deletePartner.graphql'
import partnerFormDataQuery from '../graphql/getPartner.graphql'
import updatePartnerMutation from '../graphql/updatePartner.graphql'
import { CreatePartnerMutation, DeletePartnerMutation, GetPartnerQuery, PartnerInput, UpdatePartnerMutation } from '../interfaces'

export const deletePartner = (id: string): Promise<DeletePartnerMutation | undefined> =>
  request<DeletePartnerMutation>(PARTNERS_ENDPOINT, deletePartnerMutation, { id }).catch(() => undefined)

export const getPartner = (id: string): Promise<GetPartnerQuery> =>
  request<GetPartnerQuery>(PARTNERS_ENDPOINT, partnerFormDataQuery, { id })

export const createPartner = (partner: PartnerInput): Promise<CreatePartnerMutation> =>
  request<CreatePartnerMutation>(PARTNERS_ENDPOINT, createPartnerMutation, { partner })

export const updatePartner = (partner: PartnerInput): Promise<UpdatePartnerMutation> =>
  request<UpdatePartnerMutation>(PARTNERS_ENDPOINT, updatePartnerMutation, { partner })
