import {
  DesktopDatePicker as MuiDesktopDatePicker,
  DatePickerProps,
  TimePicker as MuiTimePicker,
  DesktopDateTimePicker as MuiDesktopDateTimePicker,
  TimePickerProps,
  DesktopDateTimePickerProps,
} from '@mui/x-date-pickers'
import React from 'react'
import { Controller } from 'react-hook-form'

import { ControlledFormField } from 'common/interfaces'

import { StyledTextField } from './TextField'

type OmitUnused<T> = Omit<T, 'renderInput' | 'onChange' | 'value'>

export const DatePicker: ControlledFormField<OmitUnused<DatePickerProps>, { fullWidth: boolean }> = props => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { name, fullWidth, control, label, ...rest } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MuiDesktopDatePicker
          {...rest}
          {...field}
          onChange={(v: { toDate(): Date }) => field.onChange(v?.toDate())}
          inputFormat="D. MMM YYYY"
          // discouraged but necessary due to https://github.com/mui/mui-x/issues/4487
          disableMaskedInput
          renderInput={inputProps => <StyledTextField {...inputProps} label={label} name={name} fullWidth={fullWidth} />}
        />
      )}
    />
  )
}

export const TimePicker: ControlledFormField<OmitUnused<TimePickerProps>, { fullWidth?: boolean }> = props => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { name, fullWidth, control, label, ...rest } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MuiTimePicker
          ampm={false}
          // discouraged but necessary due to https://github.com/mui/mui-x/issues/4487
          disableMaskedInput
          {...rest}
          {...field}
          onChange={(v: { toDate(): Date }) => field.onChange(v?.toDate())}
          renderInput={inputProps => <StyledTextField {...inputProps} label={label} name={name} fullWidth={fullWidth} />}
        />
      )}
    />
  )
}

export const DateTimePicker: ControlledFormField<OmitUnused<DesktopDateTimePickerProps>, { fullWidth?: boolean }> = props => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { name, fullWidth, control, label, ...rest } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MuiDesktopDateTimePicker
          // discouraged but necessary due to https://github.com/mui/mui-x/issues/4487
          disableMaskedInput
          ampm={false}
          {...rest}
          {...field}
          onChange={(v: { toDate(): Date }) => field.onChange(v?.toDate())}
          inputFormat="D. MMM YYYY HH:mm"
          renderInput={inputProps => <StyledTextField {...inputProps} label={label} name={name} fullWidth={fullWidth} />}
        />
      )}
    />
  )
}
