import { GetPartnersQuery, ProductFieldOfLaw } from './schemaDefinition'

export enum Contact {
  Callback = 'Rückruf',
  Email = 'Individuell',
  Redirect = 'Direktanruf',
}

export enum FeatureEnum {
  Normal = 'Normal',
  Special = 'Besonders',
  Optional = 'Optional',
  Excluded = 'Ausgeschlossen',
}

export enum PaymentMethod {
  Paypal = 'Paypal',
  Creditcard = 'Kreditkarte',
  Klarna = 'Klarna',
}

export type ProductFeature = {
  id: string
  name: string
  type: string | null
  info: string
}

export type ProductTask = {
  id: string
  description: string
}

export type ProductPartnerDiscount = {
  id: string
  partners: GetPartnersQuery['partners']['list']
  discount: string
  disNetPrice: string
  disGrossPrice: string
  active: boolean
}

export type ProductPayment = {
  methods: Array<PaymentMethod>
  chancelleryId: string
}

// We cannot use `z.infer` here as the schema is more restricitve
// as what we need to prefill the form e.g. min number of payment methods
export type ProductFormValues = {
  id: string
  active: boolean
  locked: boolean
  name: string
  title: string
  subtitle: string
  netPrice: string
  grossPrice: string
  rrp: string
  contactTypes: Array<Contact>
  fieldsOfLaw: Array<ProductFieldOfLaw>
  partners: GetPartnersQuery['partners']['list']
  features: Array<ProductFeature>
  services: Array<{ service: string }>
  partnerDiscounts: Array<ProductPartnerDiscount>
  payment: { methods: Array<PaymentMethod>; chancelleryId: string }
  matching: {
    ignoreOrderVolume: boolean
  }
  marketing: {
    awinSaleType: string
  }
}

export const productInitialValues: ProductFormValues = {
  id: '',
  active: true,
  locked: false,
  name: '',
  title: '',
  subtitle: '',
  netPrice: '',
  grossPrice: '',
  rrp: '',
  contactTypes: [],
  fieldsOfLaw: [],
  partners: [],
  services: [],
  features: [],
  partnerDiscounts: [],
  payment: { methods: [], chancelleryId: '' },
  matching: {
    ignoreOrderVolume: false,
  },
  marketing: {
    awinSaleType: '',
  },
}

export type ServiceFormValues = {
  id: string
  name: string
  type: null | string
  info: string
}
export type TaskFormValues = {
  id: string
  description: string
}

export const serviceInitialValues: ServiceFormValues = {
  id: '',
  name: '',
  type: FeatureEnum.Normal,
  info: '',
}

export type PartnerDiscountFormValues = {
  id: string
  partners: GetPartnersQuery['partners']['list']
  discount: string
  disNetPrice: string
  disGrossPrice: string
  active: boolean
}

export const partnerDiscountInitialValues: PartnerDiscountFormValues = {
  id: '',
  partners: [],
  discount: '',
  disNetPrice: '',
  disGrossPrice: '',
  active: true,
}
