import { USERS_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils/requests'

import query from '../graphql/saveUserGroups.graphql'
import { SaveUserGroupsMutation } from '../interfaces/schemaDefinition'

export const saveGroups = async (groups: Array<string>): Promise<SaveUserGroupsMutation['saveGroups']> => {
  const variables = {
    groups,
  }
  const response = await request<SaveUserGroupsMutation>(USERS_ENDPOINT, query, variables)
  return response.saveGroups
}
