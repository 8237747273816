import { USERS_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils/requests'

import query from '../graphql/updateUser.graphql'
import { UpdateUserMutation, UserInput } from '../interfaces/schemaDefinition'

export const updateUser = async (user: UserInput): Promise<UpdateUserMutation> => {
  const response = await request<UpdateUserMutation>(USERS_ENDPOINT, query, { user })
  return response
}
