import React, { Fragment, RefObject, FC } from 'react'
import { Col, Row } from 'reactstrap'

import { Partner } from 'common/api/types'
import { LoggedInUser } from 'common/user-context'

import { ChancelleryLocationsQuery } from '../interfaces/schemaDefinition'

import { CallbackRequests } from './CallbackRequestsView'
import { PanelTabs } from './PanelTabs/PanelTabs'

interface CallbackEmailRequestsProps {
  user?: LoggedInUser
  chancelleryLocations: ChancelleryLocationsQuery['chancelleryLocations']['list']
  assignableUsers: Array<{ id: string; name: string }>
  assignFilter?: string
  onRowClick: (rowId: string) => React.EventHandler<React.FormEvent<HTMLTableRowElement>>
  partners: Array<Partner>
  node: RefObject<HTMLDivElement>
  hasPremium: boolean
}

export const CallbackEmailRequestsView: FC<CallbackEmailRequestsProps> = ({
  partners,
  user,
  assignableUsers,
  assignFilter,
  chancelleryLocations,
  onRowClick,
  node,
  hasPremium,
}) => (
  <Fragment>
    <PanelTabs>
      <Row>
        <Col sm="12">
          <CallbackRequests
            user={user}
            chancelleryLocations={chancelleryLocations}
            partners={partners}
            assignableUsers={assignableUsers}
            assignFilter={assignFilter}
            onRowClick={onRowClick}
            node={node}
            hasPremium={hasPremium}
          />
          {/* temporary disabled */}
          {/* <EmailRequests
            user={user}
            chancelleryLocations={chancelleryLocations}
            partners={partners}
            assignableUsers={assignableUsers}
            assignFilter={assignFilter}
            onRowClick={onRowClick}
            hasPremium={hasPremium}
          /> */}
        </Col>
      </Row>
    </PanelTabs>
  </Fragment>
)
