import { GridColumns, GridRowParams } from '@mui/x-data-grid'
import React, { FC, useState } from 'react'

import { createGridColumnsFrom } from 'common/components-mui'

import { PartnerDiscountConfigurationProps } from '../interfaces'
import { ProductPartnerDiscount, ProductFormValues } from '../interfaces/form'
import { getPrice, PriceFormat } from '../utils'

import { AccordionTable } from './AccordionTable'
import { GridColumnPartner } from './GridColumnPartner'
import { getStatus } from './GridColumnStatus'
import { PartnerDiscountDialog } from './PartnerDiscountDialog'

export const PartnerDiscountConfiguration: FC<PartnerDiscountConfigurationProps<ProductFormValues['partnerDiscounts']>> = ({
  partners,
  touchedNetPrice,
  existingProduct,
  netPrice,
  value,
  error,
  onChange,
}) => {
  const [editPartnerDiscountId, setEditPartnerDiscountId] = useState('')
  const [expanded, setExpanded] = useState<boolean>(!existingProduct)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const columns: GridColumns = createGridColumnsFrom([
    /* eslint-disable @typescript-eslint/consistent-type-assertions */
    { field: 'discount', headerName: 'Partnerrabatt', valueFormatter: params => `${params.value} %`, flex: 0.1 },
    {
      field: 'partners',
      headerName: 'Partner',
      renderCell: params => <GridColumnPartner params={params} />,
      flex: 0.3,
    },
    {
      field: 'net',
      headerName: 'Rbt. Nettopreis',
      valueGetter: params =>
        getPrice(
          PriceFormat.WithUnit,
          netPrice,
          (params.row as ProductPartnerDiscount).disNetPrice,
          touchedNetPrice,
          error?.message,
          false,
          (params.row as ProductPartnerDiscount).discount
        ),
      flex: 0.2,
    },
    {
      field: 'gross',
      headerName: 'Rbt. Bruttopreis',
      valueGetter: params =>
        getPrice(
          PriceFormat.WithUnit,
          netPrice,
          (params.row as ProductPartnerDiscount).disGrossPrice,
          touchedNetPrice,
          error?.message,
          true,
          (params.row as ProductPartnerDiscount).discount
        ),
      flex: 0.2,
    },
    { field: 'active', headerName: 'Status', renderCell: getStatus, flex: 0.1 },
  ])

  const handleRowClick = (params: GridRowParams): void => {
    setEditPartnerDiscountId(params.row.id)
    setOpenModal(true)
  }

  const handleSubmitPartnerDiscount = (partnerDiscount: ProductPartnerDiscount): void => {
    setEditPartnerDiscountId(partnerDiscount.id)

    const index = value.findIndex(s => s.id === partnerDiscount.id)
    if (index >= 0) {
      onChange([...value.slice(0, index), partnerDiscount, ...value.slice(index + 1)])
    } else {
      onChange([...value, partnerDiscount])
    }

    setOpenModal(false)
  }

  const handleDeletePartnerDiscount = (id: string): void => {
    const index = value.findIndex(s => s.id === id)
    if (index >= 0) {
      onChange([...value.slice(0, index), ...value.slice(index + 1)])
    }
    setOpenModal(false)
  }

  return (
    <AccordionTable
      title="Partnerrabatte"
      expanded={expanded}
      onChange={(_, isExpanded) => setExpanded(isExpanded)}
      columns={columns}
      rows={value}
      dialogs={
        <PartnerDiscountDialog
          open={openModal}
          partnerDiscounts={value}
          partnerDiscountId={editPartnerDiscountId}
          netPrice={netPrice}
          partners={partners}
          onClose={() => {
            setOpenModal(false)
          }}
          onSubmit={handleSubmitPartnerDiscount}
          onDelete={handleDeletePartnerDiscount}
        />
      }
      onRowClick={params => handleRowClick(params)}
      addButtonTitle="Partnerrabatt hinzufügen"
      onClickAddButton={() => {
        setEditPartnerDiscountId('')
        setOpenModal(true)
      }}
    />
  )
}
