import VacationIcon from '@mui/icons-material/Language'
import { Avatar, Box, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { FC, memo, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useSWR from 'swr'

import { VariablesState } from 'common/components-mui/MuiDataGrid/hooks/useGridData'
import { CHANCELLERIES_ENDPOINT } from 'common/constants'
import { FilterOperator } from 'common/interfaces'
import { fetcher } from 'common/utils/fetcher'

import globalHolidayQuery from '../graphql/getLocationHolidays.graphql'
import { ApiResponse, GlobalHolidayEntry, GlobalHolidaysProps } from '../interfaces'
import { GetLocationHolidaysQuery } from '../interfaces/schemaDefinition'
import { notOverYet } from '../utils'

const toGlobalHolidayEntries = (
  data: GetLocationHolidaysQuery['chancelleryLocations']['list'],
  skipLocation?: string
): Array<GlobalHolidayEntry> => {
  const list = data
    .flatMap(
      listEntry =>
        listEntry.holidays?.map(holidayEntry => ({
          id: listEntry.id,
          ...holidayEntry,
        })) ?? []
    )
    .filter((entry): entry is GlobalHolidayEntry =>
      skipLocation ? entry.id !== skipLocation && Boolean(entry.global) : Boolean(entry.global)
    )
  return list
}

const GlobalHolidaysInner: FC<GlobalHolidaysProps> = ({ title, chancelleryId, maxLocations = 100, skipLocation }) => {
  const [variables, setVariables] = useState({})
  useEffect(() => {
    setVariables({
      page: 1,
      pageSize: maxLocations,
      filters: [
        {
          name: 'chancelleryId',
          operator: FilterOperator.Equals,
          value: chancelleryId,
        },
      ],
    })
  }, [maxLocations, chancelleryId])
  const { data } = useSWR([CHANCELLERIES_ENDPOINT, globalHolidayQuery, variables], (e: string, q: string, v: VariablesState) =>
    fetcher<ApiResponse<GetLocationHolidaysQuery>>(e, q, v)
  )

  if (!data?.data) {
    return null
  }

  const globalHolidays = toGlobalHolidayEntries(data.data.chancelleryLocations.list, skipLocation).filter(notOverYet)

  return globalHolidays.length > 0 ? (
    <Box mb={3}>
      <Typography variant="h5" component="p">
        {title || 'Urlaubszeiten für alle Standorte'}
      </Typography>
      <Typography variant="overline">„Ändern“ verlässt die aktuelle Seite.</Typography>
      <List>
        {globalHolidays.map(h => (
          <ListItem key={h.start + h.id + h.end}>
            <ListItemAvatar>
              <Avatar>
                <VacationIcon aria-hidden />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Von/bis:"
              secondary={`${dayjs(h.start).format('DD.MM.YYYY')}–${dayjs(h.end).format('DD.MM.YYYY')}`}
            />
            <Link component={RouterLink} to={`/chancelleries/locations/edit/${h.id}`}>
              Ändern
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  ) : null
}

export const GlobalHolidays = memo(GlobalHolidaysInner)
