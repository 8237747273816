import { Box } from '@mui/material'
import React, { FC } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Checkbox, TextField } from 'common/components-mui/react-hook-form'

import { locationInitialValues } from '../interfaces/formSchemas'

import { InputLegend } from './InputLegend'

export const OpeningHoursSettings: FC<{ disabled: boolean }> = ({ disabled }) => {
  const { control, watch } = useFormContext<typeof locationInitialValues>()
  const openingHours = watch('openingHours')
  const { fields } = useFieldArray({
    control,
    name: 'openingHours',
  })
  return (
    <>
      <InputLegend>Öffnungszeiten</InputLegend>

      {['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'].map((day, index) => (
        <Box key={fields[index].id} mb={3} display="grid" gridTemplateColumns="16ch 1fr 1fr" gap={3}>
          <Checkbox name={`openingHours.${index}.enabled`} control={control} label={day} disabled={disabled} />
          <TextField
            name={`openingHours.${index}.begin`}
            control={control}
            label="Von"
            disabled={disabled || !openingHours[index].enabled}
          />
          <TextField
            name={`openingHours.${index}.end`}
            control={control}
            label="Bis"
            disabled={disabled || !openingHours[index].enabled}
          />
        </Box>
      ))}
    </>
  )
}
