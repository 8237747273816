import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import useSWR from 'swr'

import { Grid } from 'common/components-mui'
import { Autocomplete } from 'common/components-mui/react-hook-form'

import { fetchChancelleries } from '../actions/fetchChancelleries'
import { paymentConfigurationListState } from '../constants'
import { PaymentConfigurationProps } from '../interfaces'
import { PaymentMethod, ProductFormValues } from '../interfaces/form'
import { GetChancelleriesQuery } from '../interfaces/schemaDefinition'

import { Accordion } from './Accordion'

export const PaymentConfiguration: FC<PaymentConfigurationProps> = ({ loading, existingProduct }) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<ProductFormValues>()

  const payment = watch('payment')
  const { data: chancelleries, error } = useSWR([paymentConfigurationListState], fetchChancelleries)
  const [expanded, setExpanded] = useState<boolean>(!existingProduct)

  useEffect(() => {
    errors.payment && errors.payment.methods && setExpanded(true)
  }, [errors.payment])

  return (
    <Accordion title="Zahlung" expanded={expanded} onChange={(_, isExpanded) => setExpanded(isExpanded)}>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <Autocomplete
            control={control}
            name="payment.methods"
            label="Zahlungsart"
            multiple
            options={Object.values(PaymentMethod)}
            disabled={loading}
            ChipProps={{ color: 'primary' }}
            fullWidth
          />
        </Grid>
        <Grid xs={12} md={6}>
          <MuiAutocomplete
            options={chancelleries || []}
            noOptionsText="Es stehen keine Kanzleien mit einer hinterlegten Mollie-ID zur Auswahl."
            getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={(chancelleries && chancelleries.find(c => c.id === payment.chancelleryId)) || null}
            onChange={(_, value: GetChancelleriesQuery['chancelleries']['list'][0] | null) => {
              setValue('payment.chancelleryId', value ? value.id : '')
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Alternatives Zahlungskonto"
                helperText="Es werden nur Kanzleien angezeigt bei denen eine Mollie-ID hinterlegt worden ist."
              />
            )}
            disabled={loading || (!chancelleries && !error)}
            ChipProps={{ color: 'primary' }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Accordion>
  )
}
