import { Grid } from '@mui/material'
import React, { FC } from 'react'

import { StaticField } from 'common/components'

import { RowChancellery } from '../interfaces'

export const ChancelleryStaticFields: FC<{ chancellery: RowChancellery | null }> = ({ chancellery }) => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <StaticField label="Name" value={chancellery?.name} />
    </Grid>
    <Grid item xs={6}>
      <StaticField label="Adresse" value={chancellery?.address} />
    </Grid>
    <Grid item xs={12}>
      <StaticField label="Telefon" value={chancellery?.phone} />
    </Grid>
  </Grid>
)
