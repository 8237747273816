import { CreateMuiComponentOverrides } from 'theme/types/MuiComponentOverrides'

export const createMuiLink: CreateMuiComponentOverrides<'MuiLink'> = _theme => ({
  defaultProps: {
    underline: 'none',
  },
  variants: [
    {
      props: { variant: 'loginLink' },
      style: {
        textAlign: 'center',
      },
    },
  ],
})
