import { USERS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/getUserGroups.graphql'
import { GetUserGroupsQuery, Group } from '../interfaces/schemaDefinition'

export const fetchUserGroups = async (): Promise<Array<Group>> => {
  const response = await request<GetUserGroupsQuery>(USERS_ENDPOINT, query)
  return response.groupList
}
