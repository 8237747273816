import { useState, useEffect } from 'react'

import { BiFunction, enqueueSnackbar } from 'common/utils'

import { fetchChancelleries as actionsFetchChancelleryGroups, fetchUserListFiltered } from '../actions'
import { DEFAULT_LIST_STATE, FILTER_TRB_MANAGER } from '../constants'
import { FromPromise, User } from '../interfaces'
import { GetChancelleriesQuery } from '../interfaces/schemaDefinition'
import { findById } from '../utils'

const usersChancelleryName: BiFunction<User, FromPromise<ReturnType<typeof actionsFetchChancelleryGroups>>, string> = (
  user,
  chancelleryGroups
) => {
  const chancellery = findById(chancelleryGroups, user.organisation || '')
  return chancellery?.name ?? 'keiner Organisation zugeordnet'
}

const fetchTRBManagers = async (setTRBManager: Function): Promise<void> => {
  const userFilter = [FILTER_TRB_MANAGER]
  const trbManagers: Array<User> = (await fetchUserListFiltered(userFilter)).filter((x): x is User => Boolean(x))
  setTRBManager(trbManagers)
}

export const useTRBManagersSelection = (): {
  chancelleries: GetChancelleriesQuery['chancelleries']['list']
  trbManagers: Array<User>
  usersChancelleryName: typeof usersChancelleryName
} => {
  const [chancelleries, setChancelleries] = useState<GetChancelleriesQuery['chancelleries']['list']>([])
  const [trbManagers, setTRBManagers] = useState<Array<User>>([])

  useEffect(() => {
    actionsFetchChancelleryGroups(DEFAULT_LIST_STATE)
      .then(setChancelleries)
      .catch(() => enqueueSnackbar('Kanzleigruppen konnten nicht abgerufen werden.', { variant: 'error' }))
    fetchTRBManagers(setTRBManagers).catch(() =>
      enqueueSnackbar('TRB-Manager Daten konnten nicht abgerufen werden.', { variant: 'error' })
    )
  }, [])

  return {
    chancelleries,
    trbManagers,
    usersChancelleryName,
  }
}
