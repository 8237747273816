import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'

import query from '../graphql/saveAdviceRequest.graphql'
import { AdviceRequestFlattened, SaveAdviceRequestMutation } from '../interfaces'
import { mapAdviceRequestToSaveRequest } from '../mappings'

export const saveAdviceRequest: (adviceRequest: AdviceRequestFlattened, saveBillingCompany?: boolean) => Promise<string> = async (
  adviceRequest,
  saveBillingCompany = false
) => {
  const response = await request<SaveAdviceRequestMutation>(
    ADVICE_REQUESTS_ENDPOINT,
    query,
    mapAdviceRequestToSaveRequest(adviceRequest, saveBillingCompany)
  )
  return response.saveAdviceRequest
}
