import { Grid } from '@mui/material'
import React, { FC } from 'react'

import { StaticField } from 'common/components'

import { AdviceRequestFlattened } from '../interfaces'

export const AdviceStaticFields: FC<{ adviceRequest: AdviceRequestFlattened }> = ({ adviceRequest }) => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <StaticField label="Schadensnummer" value={adviceRequest.damageNumber} />
    </Grid>
    <Grid item xs={6}>
      <StaticField label="Land" value={adviceRequest.country} />
    </Grid>
    <Grid item xs={6}>
      <StaticField label="Ereignisart" value={adviceRequest.fieldOfLaw} />
    </Grid>
    <Grid item xs={6}>
      <StaticField label="Ursache" value={adviceRequest.description} />
    </Grid>
  </Grid>
)
