import { RadioGroup as MuiRadioGroup, FormControl, FormLabel, RadioGroupProps, FormHelperText } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { Controller } from 'react-hook-form'

import { ControlledFormField } from 'common/interfaces'

export const RadioGroup: ControlledFormField<RadioGroupProps, PropsWithChildren<{ label: string }>> = props => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { children, control, name, label, ...rest } = props
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error, invalid } }) => (
        <FormControl>
          <FormLabel>{label}</FormLabel>
          <MuiRadioGroup {...rest} {...field} name={name}>
            {children}
          </MuiRadioGroup>
          {invalid && <FormHelperText error> {error?.message} </FormHelperText>}
        </FormControl>
      )}
    />
  )
}
