import { USERS_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils/requests'

import query from '../graphql/deleteUser.graphql'
import { DeleteUserMutation, DeleteUserMutationVariables } from '../interfaces/schemaDefinition'

export const deleteUser = async (id: DeleteUserMutationVariables['id']): Promise<DeleteUserMutation> => {
  const response = await request<DeleteUserMutation>(USERS_ENDPOINT, query, { id })
  return response
}
