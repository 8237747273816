import React, { FC } from 'react'

import { FieldSet, TextAreaField, FieldRow } from 'common/components/Form'
import SVGInternalNotes from 'common/icons/bemerkung_intern.svg'
import { ButtonSecondary } from 'common/ui/Button'

interface InternalNotesProps {
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  savable: boolean
  loading: boolean
  onSubmit: React.FormEventHandler<HTMLButtonElement>
}

export const InternalNotes: FC<InternalNotesProps> = ({ value, onChange, savable, loading, onSubmit }) => (
  <FieldSet label="Bemerkungen" icon={<SVGInternalNotes width="24" height="24" />}>
    <FieldRow>
      <TextAreaField id="textarea-internalNotes" name="internalNotes" value={value} onChange={onChange} label="" />
    </FieldRow>
    {savable && (
      <ButtonSecondary loading={loading} onClick={onSubmit} className="pull-right">
        Bemerkung speichern
      </ButtonSecondary>
    )}
  </FieldSet>
)
