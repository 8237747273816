import { zodResolver } from '@hookform/resolvers/zod'
import CheckIcon from '@mui/icons-material/Check'
import { Stack, Typography } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { AbortButton, DeleteWithConfirmation, SaveButton } from 'common/components-mui'
import { TextField, Autocomplete } from 'common/components-mui/react-hook-form'
import { FeatureDialogProps } from 'packages-mui/products/interfaces'

import { featureSchema } from '../constants/formSchema'
import { FeatureEnum, serviceInitialValues } from '../interfaces/form'

import { Dialog } from './Dialog'
import { getServiceTypeIcon } from './GridColumnFeatureType'

export const FeatureDialog: FC<FeatureDialogProps> = ({ open, feature, features, onClose, onSubmit, onDelete }) => {
  const { control, handleSubmit, clearErrors, getValues, reset, watch } = useForm({
    defaultValues: serviceInitialValues,
    resolver: zodResolver(featureSchema),
  })

  const name = watch('name')

  useEffect(() => {
    const foundFeature = features.find(({ id }) => id === feature)
    if (foundFeature) {
      reset(foundFeature)
    } else {
      reset(serviceInitialValues)
    }
  }, [feature, features, reset])

  return (
    <Dialog
      open={open}
      title={`Leistung ${name ? 'bearbeiten' : 'hinzufügen'}`}
      onClose={e => {
        clearErrors()
        onClose(e)
      }}
      actions={
        <>
          {feature && (
            <DeleteWithConfirmation
              actionButtonText="Leistung löschen"
              onConfirm={async () => {
                await onDelete()
              }}
            >
              <Typography>{`Soll die Leistung "${getValues('name')}" gelöscht werden?`}</Typography>
            </DeleteWithConfirmation>
          )}
          <AbortButton
            onClick={e => {
              clearErrors()
              onClose(e)
            }}
          />
          <SaveButton
            onClick={handleSubmit(values => onSubmit({ ...values, id: values.id ? values.id : `new-${values.name}` }, true))}
            startIcon={<CheckIcon />}
          >
            {name ? 'Akzeptieren' : 'Hinzufügen'}
          </SaveButton>
        </>
      }
    >
      <Stack spacing={3} pt="1.5rem">
        <TextField control={control} label="Leistung" name="name" fullWidth />
        <Autocomplete
          control={control}
          name="type"
          label="Leistungsart"
          fullWidth
          options={Object.values(FeatureEnum)}
          renderOption={(props, option: FeatureEnum) => (
            <li {...props}>
              <>
                {getServiceTypeIcon(option)}
                <p>{option}</p>
              </>
            </li>
          )}
        />
        <TextField fullWidth control={control} name="info" label="Zusätzliche Informationen" multiline />
      </Stack>
    </Dialog>
  )
}
