import { KeyStringValueMap } from '../interfaces'

export const PRODUCT_CATEGORIES: KeyStringValueMap = {
  FEATURED: 'Highlight',
  FRONTPAGE: 'Hauptseite',
  FESTPREIS: 'Festpreis',
  INDIVIDUAL: 'Individual',
}

export const PRODUCT_TYPES: KeyStringValueMap = {
  CONSUMER: 'Consumer',
  BUSINESS: 'Business',
}

export const PRODUCT_CONTACT_TYPES: KeyStringValueMap = {
  REDIRECT: 'Direktanruf',
  CALLBACK: 'Rückruf',
  EMAIL: 'Individuell',
}
