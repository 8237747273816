import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton } from '@mui/material'
import React, { FC } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { AddButton } from 'common/components-mui'
import { TextField, Autocomplete } from 'common/components-mui/react-hook-form'
import { FieldOfLaw } from 'common/graphql/schemaDefinition'

import { FieldOfLawPhoneEntryProps } from '../interfaces'
import { locationInitialValues } from '../interfaces/formSchemas'
import { getNewPhoneEntry, getRemainingFols } from '../utils'

import { InputLegend } from './InputLegend'

export const FieldOfLawPhoneEntry: FC<FieldOfLawPhoneEntryProps> = ({ disabled }) => {
  const { control, watch } = useFormContext<typeof locationInitialValues>()
  const fieldOfLawPhones = watch('fieldOfLawPhones')
  const { fields, remove, append } = useFieldArray({ control, name: 'fieldOfLawPhones' })
  const getFolOptions = (index: number): Array<FieldOfLaw> =>
    getRemainingFols(fieldOfLawPhones, fieldOfLawPhones[index].fieldOfLaw)

  return (
    <>
      <InputLegend>Telefon-Nr. pro Rechtsgebiet</InputLegend>
      <Box mb={3}>
        <AddButton aria-label="Telefonnummer hinzufügen" disabled={disabled} onClick={() => append(getNewPhoneEntry())} />
      </Box>
      {fields.map((field, index) => (
        <Box key={field.id} mb={2} display="grid" gridTemplateColumns="4fr 4fr 1fr" alignItems="center" gap={3}>
          <Autocomplete
            control={control}
            options={getFolOptions(index)}
            name={`fieldOfLawPhones.${index}.fieldOfLaw`}
            label="Rechtsgebiet"
            getOptionLabel={f => (typeof f === 'string' ? f : f.name)}
            isOptionEqualToValue={
              (option: FieldOfLaw, value: FieldOfLaw) =>
                option.id === value.id && option.name === value.name /* Default is strict equality */
            }
            disabled={disabled}
          />
          <TextField control={control} name={`fieldOfLawPhones.${index}.phone`} label="Telefonnummer" disabled={disabled} />
          <Box>
            <IconButton onClick={() => remove(index)} disabled={disabled} size="large">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </>
  )
}
