import { USERS_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils'

import query from '../graphql/getUsersFiltered.graphql'
import { Filter, GetUsersFilteredQuery, GetUsersFilteredQueryVariables } from '../interfaces/schemaDefinition'

export const fetchUserListFiltered = async (filters: Array<Filter>): Promise<GetUsersFilteredQuery['userList']['list']> => {
  const variables: GetUsersFilteredQueryVariables = {
    filters,
  }
  const response = await request<GetUsersFilteredQuery>(USERS_ENDPOINT, query, variables)
  return response.userList.list
}
