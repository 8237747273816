import AddCircleIcon from '@mui/icons-material/AddCircleOutline'
import { DataGrid } from '@mui/x-data-grid'
import React, { FC, useState } from 'react'

import { SaveButton } from 'common/components-mui'

import { AccordionTableProps } from '../interfaces'

import { Accordion } from './Accordion'

export const AccordionTable: FC<AccordionTableProps> = ({
  title,
  columns,
  rows,
  dialogs,
  initialState,
  onRowClick,
  addButtonTitle,
  onClickAddButton,
  expanded,
  onChange,
  children,
}) => {
  const [pageSize, setPageSize] = useState<number>(20)

  return (
    <Accordion title={title} dialogs={dialogs} expanded={expanded} onChange={onChange}>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        rowsPerPageOptions={[20, 30, 50]}
        pageSize={pageSize}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        columns={columns}
        rows={rows}
        initialState={initialState}
        onRowClick={onRowClick}
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .last-row-cell': {
            padding: 0,
          },
        }}
      />
      <SaveButton startIcon={<AddCircleIcon />} onClick={onClickAddButton} submit={false} sx={{ mt: '2rem' }}>
        {addButtonTitle}
      </SaveButton>
      {children}
    </Accordion>
  )
}
