/* tslint:disable: no-object-mutation no-array-mutation */
import { ROLES, ROLE_IDS } from 'common/constants'
import { SortDirection } from 'common/interfaces'

import { Filter, FilterOperator, QueryUserListArgs } from '../interfaces'

export * from './userSchema'
export * from './chancellerySelectionListState'

export const DEFAULT_LIST_STATE: QueryUserListArgs = {
  page: 1,
  pageSize: 100,
  sort: {
    sortBy: 'name',
    sortDirection: SortDirection.Asc,
  },
  filters: [],
}

export const FILTER_TRB_MANAGER: Filter = {
  name: 'trbManagerFilter',
  operator: FilterOperator.HasRole,
  value: ROLES[ROLE_IDS.TRBManager].id,
}
