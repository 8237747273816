import { FilterOperator } from 'common/components-mui'
import { ListState, SortDirection } from 'common/interfaces'

export * from './formSchema'

export const paymentConfigurationListState: ListState = {
  page: 1,
  pageSize: 100,
  sort: {
    sortBy: 'name',
    sortDirection: SortDirection.Asc,
  },
  filters: [
    {
      name: 'mollieId',
      operator: FilterOperator.NotEmpty,
      value: '',
    },
  ],
}
