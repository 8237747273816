import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import StarsIcon from '@mui/icons-material/Stars'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import React, { FC } from 'react'

import { GridColumnFeatureTypeProps } from '../interfaces'
import { FeatureEnum } from '../interfaces/form'

export const getServiceTypeIcon = (type: FeatureEnum): JSX.Element => {
  if (type.includes(FeatureEnum.Normal)) {
    return <TaskAltIcon sx={{ mr: '0.5rem', color: 'success.main' }} />
  } else if (type.includes(FeatureEnum.Special)) {
    return <StarsIcon sx={{ mr: '0.5rem', color: '#ffc107' }} />
  } else if (type.includes(FeatureEnum.Optional)) {
    return <HelpOutlineIcon sx={{ mr: '0.5rem', color: 'info.dark' }} />
  } else {
    return <DoNotDisturbAltIcon sx={{ mr: '0.5rem', color: 'error.dark' }} />
  }
}

export const GridColumnFeatureType: FC<GridColumnFeatureTypeProps> = ({ type }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {getServiceTypeIcon(type)}
    {type}
  </div>
)
