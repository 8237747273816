import { GridCellParams } from '@mui/x-data-grid'
import React, { FC } from 'react'

import { Badge, CenterBox } from 'common/components-mui'

export const getStatus: FC<GridCellParams> = (params: GridCellParams) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const {
    row: { active },
  } = params

  return active ? (
    <CenterBox>
      <Badge color="success">Aktiv</Badge>
    </CenterBox>
  ) : (
    <CenterBox>
      <Badge color="default">Inaktiv</Badge>
    </CenterBox>
  )
}
