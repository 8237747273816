import { ADVICE_REQUESTS_ENDPOINT } from 'common/constants'
import { request } from 'common/utils'
import { CheckDamageNumberQuery } from 'packages/chancellery-search/interfaces/schemaDefinition'

import query from '../graphql/checkDamageNumber.graphql'

export const checkDamageNumber = async (damageNumber: string): Promise<CheckDamageNumberQuery['checkDamageNumber']> => {
  const response = await request<CheckDamageNumberQuery>(ADVICE_REQUESTS_ENDPOINT, query, { damageNumber })
  return response.checkDamageNumber
}
