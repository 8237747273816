import useSWR from 'swr'

import { enqueueSnackbar } from 'common/utils'

import { fetchChancelleries } from '../actions'
import { listState } from '../constants'
import { ChancellerySelection, FromPromise } from '../interfaces'

export const useChancellerySelection = (
  selectedChancellery?: string
): ChancellerySelection<FromPromise<ReturnType<typeof fetchChancelleries>>> => {
  const { data: chancelleries = [], error } = useSWR([listState], fetchChancelleries)

  if (typeof selectedChancellery !== 'string') {
    throw new Error('Name for ChancellerySelection input points to non-string value.')
  }

  if (error) {
    enqueueSnackbar('Kanzleien konnten nicht abgerufen werden', { variant: 'error' })
  }

  const optionObjects =
    chancelleries?.flatMap(chancellery => {
      const mainLocationEntry = { id: chancellery.id, name: chancellery.name }
      const locations = chancellery.chancelleryLocations.map(location => ({
        ...location,
        name: `-- ${location.address.city}, ${location.address.street}`,
      }))

      const hasMultipleLocations = locations.length > 1

      return hasMultipleLocations ? [mainLocationEntry, ...locations] : [mainLocationEntry]
    }) ?? []

  const mapOptionsIds = (): Array<string> => optionObjects.map(({ id }) => id)

  const getOptionLabel = (option: string): string => {
    const foundOption = optionObjects.find(val => val.id === option)
    return foundOption ? foundOption.name : ''
  }

  const getOrganisationType = (chancellery: string): 'chancelleryGroup' | null =>
    chancelleries?.find(g => g.id === chancellery) ? 'chancelleryGroup' : null

  return {
    getOrganisationType,
    chancelleries,
    disabled: !chancelleries && !error,
    options: mapOptionsIds(),
    getOptionLabel,
  }
}
