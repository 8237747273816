import { DEVK_PARTNERS } from 'common/constants'

export { addOptionalPropIf } from './addAdditionalPropIf'
export { booleanToLabel, dateToLabel, numberToLabel, toLabel } from './toLabel'
export { CircularBuffer } from './buffer'
export { convertToTagValues } from './tags'
export { currencyFormatter, isValidAmount } from './currency'
export { enqueueSnackbar } from './enqueueSnackbar'
export { fetcher } from './fetcher'
export { filterSuggestions } from './suggestions'
export { formatDate, formatDateTime } from './date'
export { getQueryParam, parseQuery } from './location'
export { namedTagChange } from './namedTagChange'
export { pickListState } from './pickListState'
export { pushSearch } from './pushSearch'
export { replaceAt } from './replaceAt'
export { request } from './requests'
export { toggleElement } from './toggleElement'
export * from './_utils'
export * from './components'
export * from './functional'
export * from './number'
export * from './roles'
export * from './string'
export * from './uploadFiles'

const DEVK_PARTNER = [
  'devk',
  'devk-rechtskunden',
  'devk-allgemein',
  'devk-dsgvo',
  'devk-steuerungstarif',
  'devk-gewerbe',
  'devk-kfz',
  'devk-diesel',
  'devk-trb',
  'devk-trb-sb',
  'devk-direktsteuerung',
  'devk-direktsteuerung-2',
  'devk-ausland',
]

export const isDevk = (partnerId: string | undefined): boolean => (partnerId ? DEVK_PARTNERS.includes(partnerId) : false)
export const isExtendedDevkPartner = (partnerId: string | undefined): boolean => DEVK_PARTNER.includes(partnerId || '')
