import { all, not } from 'common/utils'
import { isInMinutes } from 'common/utils/date'

export const CONTACT_TYPE_LABELS = {
  REDIRECT: 'Anruf',
  CALLBACK: 'Rückruf',
  EMAIL: 'Individuell',
  MAIL: 'Individuell',
  NOT_SET: 'Nicht gesetzt',
}

export const PERSON_DATA_LABELS = {
  firstname: 'Vorname',
  lastname: 'Nachname',
  street: 'Straße',
  streetNr: 'Haus-Nr',
  zip: 'Postleitzahl',
  city: 'Stadt',
  email: 'E-Mail',
  phone: 'Telefon',
}

export const PERSON_DATA: { [key: string]: string } = {
  Vorname: 'firstname',
  Nachname: 'lastname',
  Straße: 'street',
  'Haus-Nr': 'streetNr',
  Postleitzahl: 'zip',
  Stadt: 'city',
  'E-Mail': 'email',
  Telefon: 'phone',
}

// Time checks for lawyer element color markings
export const isUrgentTime = isInMinutes(15)
export const isSoonTime = all(isInMinutes(60), not(isUrgentTime))

export const noRevenueReasons: Array<string> = [
  'Kunde wollte mandatieren, hat sich dann jedoch nicht mehr gemeldet',
  'Kunde hat ein Mandat erteilt, die Sache hat sich jedoch vor Tätigwerden erledigt',
]
