import { USERS_ENDPOINT } from 'common/constants/endpoints'
import { request } from 'common/utils/requests'

import query from '../graphql/createUser.graphql'
import { CreateUserMutation, UserInput } from '../interfaces/schemaDefinition'

export const createUser = async (user: UserInput): Promise<CreateUserMutation> => {
  const response = await request<CreateUserMutation>(USERS_ENDPOINT, query, { user })
  return response
}
