import { Box, Chip } from '@mui/material'
import React, { FC, useState } from 'react'
import * as z from 'zod'

import { AddButton } from '../AddButton'

import { StyledTextField } from './TextField'

export const schema = z.string().regex(/^\d{1,5}$/, 'Bitte PLZ(-Bereiche) von 0 - 99999 eingeben')

type GroupInputType = FC<{
  value: Array<string>
  onChange: (v: Array<string>) => void
  error?: boolean
  helperText?: string
  name: string
  label: string
  sort?: (a: string, b: string) => number
  disabled?: boolean
}>

export const GroupInput: GroupInputType = props => {
  const { onChange, value, error, helperText, name, sort, label, disabled } = props
  const [inputError, setInputError] = useState(helperText)
  const [input, setInput] = useState('')

  const handleAdd = (): void => {
    try {
      schema.parse(input)
      const set = new Set(value)
      set.add(input)
      const added = Array.from(set)

      if (sort) {
        // eslint-disable-next-line fp/no-mutating-methods
        added.sort(sort)
      }
      setInput('')
      onChange(added)
    } catch (addError: unknown) {
      if (addError instanceof z.ZodError) {
        setInputError(addError.issues[0].message)
      }
    }
  }

  const handleRemove = (valueToRemove: string): void => {
    const removed = value.filter(v => v !== valueToRemove)
    onChange(removed)
  }

  return (
    <Box display="flex" gap={3} alignItems="center">
      <StyledTextField
        onChange={e => {
          setInputError('')
          setInput(e.target.value)
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleAdd()
          }
        }}
        value={input}
        name={name}
        disabled={disabled}
        label={label}
        error={!!inputError || error}
        helperText={inputError}
        variant="outlined"
      />
      <AddButton onClick={handleAdd} />
      <Box display="flex" gap={1}>
        {value.map((item, index) => (
          <Chip key={item + index} variant="outlined" color="primary" label={item} onDelete={() => handleRemove(item)} />
        ))}
      </Box>
    </Box>
  )
}
